.particles {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  background-color: #000;
  position: relative;
  font-family: Oswald, sans-serif;
  .title {
    position: absolute;
    right: 10%;
    top: calc(50%);
    transform: translateY(-50%);
    z-index: 2;
    font-size: 60px;
    font-weight: bold;
    line-height: 70px;
    span {
      display: block;
      text-align: center;
      &:first-child {
        color: #ef0d33;
      }
      &:last-child {
        color: #ef0d33;
      }
    }
  }
  .tsparticles {
    height: 100%;
  }
}
