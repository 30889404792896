// @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  font-family: $font-family;
  font-size: $font-size;
  font-style: normal;
  line-height: 1.42857;
  color: light-neutral(0);
  background-color: light-neutral(900);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

.no {
  &\:width {
    width: 0;
  }

  &\:height {
    height: 0;
  }

  &\:border-radius {
    border-radius: 0;
  }

  &\:padding {
    padding: 0;
  }

  &\:margin {
    overflow: 0;
  }
}

.safe-area {
  &:not(:first-child) {
    margin-top: 1.5rem;
  }

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

h1 {
  -ievar-color: dark-neatural(0);
  color: dark-neatural(0);
  font-size: 6.25em;
  font-weight: 900;
  margin: 0.3em 0;
  line-height: 1.2;
}

h2 {
  -ievar-color: dark-neatural(0);
  color: dark-neatural(0);
  font-size: 4.6875em;
  font-weight: 900;
  margin: 0.4em 0;
  line-height: 1.2;
}

h3 {
  -ievar-color: dark-neatural(0);
  color: dark-neatural(0);
  font-size: 3.75em;
  font-weight: 700;
  margin: 0.5em 0;
  line-height: 1.2;
}

h4 {
  -ievar-color: dark-neatural(0);
  color: var(--subtitle-color);
  font-size: 2.5em;
  font-weight: 700;
  margin: 0.6em 0;
  line-height: 1.25;
}

h5 {
  -ievar-color: dark-neatural(0);
  color: var(--subtitle-color);
  font-size: 1.875em;
  font-weight: 500;
  margin: 0.7em 0;
  line-height: 1.25;
}

h6 {
  -ievar-color: dark-neatural(0);
  color: var(--subtitle-color);
  font-size: 1.125em;
  font-weight: 500;
  margin: 0.8em 0;
  line-height: 1.25;
}

p {
  font-size: 1em;
}

a:not([class]) {
  color: currentColor;
  text-decoration: none;

  &:focus {
    outline: 2px solid rgba(0, 0, 0, 0.4);
    outline-offset: 2px;
  }
}

p,
ul,
ol,
dl,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
pre,
form,
table {
  &:first-child {
    margin-top: 0;
  }

  &:last-chaild {
    margin-bottom: 0;
  }
}

dd,
dd + dt,
li + li {
  margin-top: 4px;
}

ul ul,
ol ul,
ul ol,
ol ol {
  margin-top: 4px !important;
}

blockquote {
  border: none;

  &::before {
    content: '201C';
  }

  &::after {
    content: '201D';
  }

  &::before {
    float: left;
    margin-left: -1em;
    text-align: right;
    width: 1em;
  }

  & > :last-child {
    display: inline-block;
  }
}

table {
  border-collapse: separate;
  width: 100%;
  border-spacing: 0;
}

td,
th {
  border: none;
  text-align: left;
}

caption {
  font-size: 1.42em;
  -ievar-color: var(--subtitle-color);
  color: var(--subtitle-color);
  line-height: 1.2;
  font-weight: 500;
  letter-spacing: -0.008em;
  margin-top: 1em;
  margin-bottom: 8px;
  text-align: left;
}

figure {
  margin: 0;
}

@media #{$media-md} {
  h1 {
    font-size: 5.25em;
  }

  h2 {
    font-size: 3.6875em;
  }

  h3 {
    font-size: 3em;
  }

  h4 {
    font-size: 2.25em;
  }
}

@media #{$media-sm} {

  h1 {
    font-size: 2.25em;
    font-weight: 700;
    margin: 0.5em 0;
  }

  h2 {
    font-size: 1.95em;
    font-weight: 700;
    margin: 0.6em 0;
  }

  h3 {
    font-size: 1.75em;
    margin: 0.7em 0;
  }

  h4 {
    font-size: 1.5em;
    margin: 0.8em 0;
  }

  h5 {
    font-size: 1.25em;
    margin: 0.9em 0;
  }

  h6 {
    font-size: 1em;
    margin: 1em 0;
  }

  p {
    font-size: 0.9em;
  }
}
