.organization-items {
  margin: 0 auto;
  width: max-content;
  position: relative;
  &::before {
    content: '';
    height: calc(100% - 90px);
    width: 1px;
    background-color: #838383;
    position: absolute;
    top: 45px;
  }
  &-item {
    align-items: center;
    margin: 80px 0;
    position: relative;
    padding-left: 50px;
    &-detail {
      border-radius: 30px;
      background-color: #1d1d1d;
      padding: 15px;
      display: flex;
      align-items: center;
      width: 400px;
      z-index: 1;
      img {
        border-radius: 15px;
        width: 60px;
        height: 60px;
        object-fit: cover;
      }
      &-desc {
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 60px);
        &-title {
          font-size: 20px;
        }
        &-department {
          font-size: 13px;
          color: #838383;
        }
        .social-media {
        }
      }
      &::before {
        content: '';
        height: 1px;
        width: 50px;
        background-color: #838383;
        position: absolute;
        left: 0;
        z-index: -1;
      }
    }
    .organization-items {
      &::before {
        display: none;
      }
      .organization-items-item {
        &.has-manager,
        &.has-sector {
          padding-left: 50px;
          .organization-items {
            padding: 25px 0 0 100px;
            margin: 0;
            left: 25px;
            &::before {
              display: block;
              height: calc(100% - 44.5px);
              top: 0;
              left: 0;
            }
            &::after {
              content: '';
              height: 1px;
              width: 125px;
              background-color: #838383;
              position: absolute;
              left: -125px;
              bottom: 44px;
              display: none;
            }
            &-item {
              padding: 0;
              margin: 30px 0;
              &-detail {
                &::before {
                  width: 125px;
                  left: -50px;
                }
                &::after {
                  width: 250px;
                  left: -50px;
                }
              }
              .organization-items {
                &-item {
                  &-detail {
                    &::before {
                      width: 100px;
                      left: -100px;
                    }
                  }
                }
              }
            }
          }
          &:last-child {
            > .organization-items {
              &::after {
                display: block;
              }
            }
          }
        }
      }
    }
  }
  &-sector-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 15px;
  }
}

@media #{$media-md} {
  .organization-items {
    &-item {
      &-detail {
        width: 265px;
      }
    }
  }
}

@media #{$media-sm} {
  .organization-items {
    margin: 0 auto;
    width: max-content;
    position: relative;
    box-sizing: border-box;
    &::before {
      content: '';
      height: calc(100% - 55px);
      width: 1px;
      background-color: #838383;
      position: absolute;
      top: 27.5px;
    }
    &-item {
      align-items: center;
      margin: 15px 0;
      position: relative;
      padding-left: 15px;
      box-sizing: border-box;
      &-detail {
        border-radius: 5px;
        background-color: #1d1d1d;
        padding: 7.5px;
        display: flex;
        align-items: center;
        width: 205px;
        z-index: 1;
        img {
          border-radius: 5px;
          width: 40px;
          height: 40px;
          object-fit: cover;
        }
        &-desc {
          width: calc(100% - 40px);
          padding: 0 15px;
          &-title {
            font-size: 12px;
          }
          &-department {
            font-size: 8px;
            color: #838383;
          }
        }
        &::before {
          content: '';
          height: 1px;
          width: 15px;
          background-color: #838383;
          position: absolute;
          left: 0;
          z-index: -1;
        }
      }
      .organization-items {
        box-sizing: border-box;
        &::before {
          display: none;
        }
        .organization-items-item {
          &.has-manager,
          &.has-sector {
            padding-left: 15px;
            box-sizing: border-box;
            .organization-items-item-detail {
              &::before {
                width: 30px !important;
                left: -15px !important;
              }
            }
            .organization-items {
              padding: 15px 0 0 30px;
              margin: 0;
              left: 15px;
              box-sizing: border-box;
              &::before {
                display: block;
                height: calc(100% - 27.5px);
                top: 0;
                left: 0;
              }
              &::after {
                content: '';
                height: 1px;
                width: 45px;
                background-color: #838383;
                position: absolute;
                left: -45px;
                bottom: 26.5px;
                display: none;
              }
              &-item {
                padding: 0;
                margin: 10px 0;
                &-detail {
                  box-sizing: border-box;
                  &::before {
                    width: 30px;
                    left: -15px;
                  }
                  &::after {
                    width: 30px;
                    left: -15px;
                  }
                }
                .organization-items {
                  &-item {
                    &-detail {
                      &::before {
                        width: 30px !important;
                        left: -30px !important;
                      }
                    }
                  }
                }
              }
            }
            &:last-child {
              > .organization-items {
                &::after {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
    &-sector-title {
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 15px;
    }
  }
}
