.home-container {
  background: #fff;
  padding: 0;
  margin: 0;
  border-bottom-left-radius: 200px;
  border-bottom-right-radius: 200px;
  padding-bottom: 50px;
  overflow: hidden;
}
.content-container {
  padding-top: 132px;
}
.page-container {
  margin: 0;
  padding: 0;
  margin: 5% 0;
}
.reverse-container {
  margin-top: -132px;
  padding-top: 132px;
}
.contact-container {
  padding: 0;
  .asg-footer {
    padding-top: 50px;
    margin-top: 0;
  }
}
a {
  outline: none !important;
}
.firm-container {
  position: relative;
  z-index: 1;
  h1 {
    font-size: 48px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 41px;
  }
  h2 {
    font-size: 45px;
    font-weight: bold;
    color: #838383;
    margin-bottom: 37px;
  }
  p {
    font-size: 16px;
    color: #fff;
    margin-bottom: 25px;
    display: block;
    max-width: 80%;
    word-break: break-word;
  }
  .image {
    max-width: 285px;
    height: 90px;
    margin-bottom: 52px;
  }
  & .row > .col-xl-6:nth-child(2) p {
    font-size: 15px;
  }
  iframe {
    width: 100%;
    height: 317px;
    border-radius: 40px;
    border: none;
  }
}

h1.single-title {
  font-size: 45px;
  color: #838383;
  font-weight: 700;
  width: 100%;
  font-family: Oswald;
}

h1.text-center {
  text-align: center;
}

.content-container {
  h1 {
    font-size: 45px;
    color: #838383;
    font-weight: 700;
    width: 100%;
    margin-bottom: 25px;
  }
  h2 {
    margin-top: 0;
    margin-bottom: 57px;
    font-size: 65px;
    color: #e4e4e4;
    font-weight: 500;
    width: 100%;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 25px;
  }
  p {
    font-size: 16px;
    max-width: 90%;
  }
}

.our-sectors {
  margin-top: 50px;
  padding-top: 50px;
}
.CareerPageBtn {
  margin-bottom: 20px;
}
@media #{$media-xl} {
  .page-container {
    padding: 0 calc(15px + 1rem);
    & > .container {
      padding: 15px;
    }
  }
}

@media screen and (max-width: 1600px) {
  .home-container {
    border-bottom-left-radius: 150px;
    border-bottom-right-radius: 150px;
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 1350px) {
  .our-sectors {
    margin-top: 29px;
    padding-top: 25px;
  }
}

@media #{$media-lg} {
  .firm-container {
    h1 {
      font-size: 36px;
    }
    h2 {
      font-size: 45px;
    }
    p {
      font-size: 14px;
    }
    .image {
      width: 200px;
    }
    & .row > .col-xl-6:nth-child(2) p {
      font-size: 13px;
    }
    iframe {
      height: 250px;
    }
  }
  .home-container {
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
  }
}

@media #{$media-sm} {
  .content-container {
    padding: 0 35px;
  }
  h1.single-title {
    font-size: 20px;
    margin: 25px 0 !important;
  }
  .our-sectors {
    margin-top: 68px;
    padding-top: 0;
  }
  .page-container {
    margin-top: 50px;
    padding: 0 15px;
  }
  .container-extended {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .firm-container {
    margin-top: 0px;
    p {
      max-width: 100%;
    }
    .row {
      margin: 0;
    }
    .container {
      width: 100%;
      bottom: 0;
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: rgba(247, 242, 242, 0.952);
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #838383;
      }
    }
  }
  .content-container {
    h1 {
      font-size: 30px;
    }
    h2 {
      font-size: 30px;
    }
    p {
      font-size: 14px;
    }
    &.single-title {
      h1 {
        margin: 35px 0;
      }
    }
  }
  .home-container {
    padding-bottom: 0;
  }
}

@media #{$media-xs} {
  h1.single-title {
    font-size: 20px;
    margin: 25px 0 !important;
  }
  .content-container h3 {
    font-size: 18px;
  }
  .firm-container {
    h1 {
      font-size: 23px;
    }
    h2 {
      font-size: 23px;
    }
    p {
      font-size: 12px;
    }
    .image {
      width: max-content;
      height: 50px;
      margin-bottom: 20px;
      max-width: 200px;
    }
    & .row > .col-xl-6:nth-child(2) p {
      font-size: 13px;
    }
    iframe {
      height: 223pt;
    }
  }
}
