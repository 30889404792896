.carrier-head-mobile {
  display: flex;
  align-items: center;
  .back {
    margin-right: 15px;
    background: transparent;
    border: none;
    line-height: 45px;
    span {
      &::before {
        color: #838383;
      }
    }
  }
  h1 {
    color: #838383;
    margin: 0;
  }
}
.carrier-wrapper {
  background: #1d1d1d;
  display: flex;
  flex-direction: column;
  margin: 0 auto 50px auto;
  border-radius: 71px;
  padding: 41px 106px;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  .filter {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 25px 0;
    & > button {
      background: #838383;
      border-radius: 23px;
      min-width: 100px;
      max-width: 300px;
      height: 40px;
      text-align: center;
      color: #fff;
      border: none;
      margin-right: 20px;
      cursor: pointer;
      transition: all 0.3s;
      padding: 0 15px;
      position: relative;
      & span {
        margin-left: 6px;
      }

      .dropdwon-items {
        padding: 10px 0;
        margin: 0;
        display: none;
        list-style: none;
        transition: all 0.3s;
        position: absolute;
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);
        top: 10px;
        opacity: 0;
        pointer-events: none;
        li {
          font-size: 12px;
          color: #838383;
          transition: all 0.1s;
          margin: 0;
          button {
            background: 0;
            padding: 10px;
            margin: 0;
            border: none;
            display: block;
            width: 100%;
            min-width: 100px;
            overflow: hidden;
            background-color: #fff;
            white-space: nowrap;
            cursor: pointer;
            transition: all, 0.4s;
          }
          &:first-child {
            button {
              border-top-right-radius: 23px;
              border-top-left-radius: 23px;
            }
          }
          &:last-child {
            button {
              border-bottom-left-radius: 25px;
              border-bottom-right-radius: 25px;
            }
          }
          &:hover {
            button {
              background-color: #ededed;
            }
          }
        }
      }
      &:hover {
        background: #fff;
        color: #838383;
        & > span {
          rotate: 180deg;
          transition: all 0.3s;
          &::before {
            color: #838383;
          }
        }
        .dropdwon-items {
          opacity: 1;
          top: 40px;
          pointer-events: auto;
          transition: all, 0.4s;
        }
      }
      &:hover .dropdwon-items {
        display: block;
      }
    }
  }

  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(1);
    }
  }

  .category-content {
    display: flex;
    flex-direction: column;

    &:first-child {
      margin-top: 15px;
    }

    .title {
      font-size: 35px;
      color: #fff;
      margin-bottom: 30px;
      border-bottom: 1px solid #4b4b4b;
      padding-bottom: 20px;
      width: 90%;
      font-weight: bold;
    }
    .advert-item {
      display: flex;
      flex-direction: row;
      margin-bottom: 15px;
      border-bottom: 1px solid #222;
      padding-bottom: 15px;
      width: 90%;
      .image {
        width: 45px;
        margin-right: 17px;
      }
      .content {
        width: 90%;
        display: flex;
        flex-direction: column;
        & > span {
          font-size: 18px;
          margin-bottom: 8px;
        }
        .tags {
          display: flex;
          flex-direction: row;
          margin: 0;
          padding: 0;
          li {
            font-size: 14px;
            list-style: square;
            margin-right: 21px;
            margin-top: 0;
            &:first-child {
              list-style: none;
            }
          }
        }
      }
    }
  }
  .detail-wrapper {
    background: #1d1d1d;

    .detail-header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      border-bottom: 1px solid #838383;
      padding-bottom: 15px;
      .image {
        width: 90px;
        margin-right: 17px;
        min-width: 90px;
      }
      .content {
        margin-right: auto;
        margin-left: 0;
        display: flex;
        flex-direction: column;
        & > span {
          font-size: 18px;
          margin-bottom: 3px;
        }
        .tags {
          display: flex;
          flex-direction: row;
          margin: 0;
          padding: 0;
          margin-bottom: 3px;

          li {
            font-size: 14px;
            list-style: square;
            margin-right: 21px;
            margin-top: 0;
            &:first-child {
              list-style: none;
            }
          }
        }
        .social {
          display: flex;
          flex-direction: row;
          margin: 0;
          padding: 0;
          li {
            font-size: 14px;
            list-style: none;
            margin-right: 10px;
            margin-top: 0;
            padding: 5px 0;
          }
        }
      }
      .button-wrapper {
        display: flex;
        justify-content: center;
        button,
        a {
          background: #838383;
          border-radius: 23px;
          cursor: pointer;
          width: 135px;
          height: 40px;
          text-align: center;
          color: #fff;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          &.close-button {
            // background: #4b4b4b;
            width: 40px;
            margin-left: 10px;
          }
          &:hover {
            background: #4b4b4b;
          }
        }
        .close-button {
          width: 35px !important;
        }
      }
    }
    .detail-content {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      position: relative;

      fieldset {
        border: none;
      }

      .title {
        margin-bottom: 20px;
        font-size: 22px;
        font-weight: bold;
      }
    }
  }
}
.scroll-area {
  height: fit-content;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(247, 242, 242, 0.952);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #838383;
  }
  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  .spinner {
    width: 100%;
    margin-top: 30px;
    &:before {
      content: '';
      box-sizing: border-box;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      margin-left: -10px;
      border-radius: 50%;
      border: 2px solid transparent;
      border-top-color: #07d;
      border-bottom-color: #07d;
      animation: spinner 0.8s ease infinite;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .carrier-wrapper {
    padding: 41px 106px;
    .filter {
      padding: 20px 0 0 62px;
    }
  }
}
@media only screen and (max-width: 768px) {
  .carrier-wrapper {
    padding: 40px 20px;
    border-radius: 35px;
    margin: 0 0 25px 0;
    width: 100%;
    .scroll-area {
      height: fit-content;
      overflow: auto;
      font-size: 12px;
    }
    .filter {
      padding: 0px;
    }
    .detail-wrapper {
      .detail-header {
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        &-head {
          display: flex;
          align-items: center;
          button {
            margin: 0 2.5px !important;
          }
        }
        .tags {
          padding-left: 15px !important;
          li {
            width: 100%;
            font-size: 12px !important;
            &:first-child {
              list-style-type: square !important;
            }
          }
        }
        .social {
          li {
            font-size: 12px !important;
          }
        }
        .image {
          width: 50px;
        }
        .content {
          order: 2;
          margin-top: 15px;
          margin-right: auto;
          width: 100%;
          margin-left: 0;
          .tags {
            flex-wrap: wrap;
            display: flex;
          }
          &-title {
            font-size: 14px;
            font-weight: bold;
          }
        }
        .button-wrapper {
          order: 1;
          button {
            margin-bottom: 10px;
          }
        }
      }
    }

    .category-content {
      .advert-item {
        .image {
          width: 50px;
        }
        .content {
          & > span {
            font-size: 16px;
            font-weight: bold;
          }
          .tags {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            padding-left: 15px;
            li {
              font-size: 14px;
              margin: 0;
              &:first-child {
                list-style-type: square;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 500px) {
  .carrier-wrapper {
    .detail-wrapper {
      .detail-header {
        .image {
          width: 50px;
          min-width: 50px;
        }
        .content {
          .tags {
          }
        }
        .button-wrapper {
          order: 1;
          button,
          a {
            background: #838383;
            border-radius: 23px;
            cursor: pointer;
            width: 105px;
            height: 35px;
            text-align: center;
            color: #fff;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
          }
        }
      }
    }

    .category-content {
      margin-top: 0 !important;
      .title {
        font-size: 20px;
      }
      .advert-item {
        .content {
          & > span {
            font-size: 14px;
            font-weight: bold;
          }
          .tags {
            padding-left: 15px;
            li {
              font-size: 11px;
              margin: 0 5px;
              width: 100%;
              &:first-child {
                list-style-type: square;
              }
            }
          }
        }
      }
    }
    .filter {
      justify-content: center;
      & > button {
        width: fit-content;
        min-width: calc(50% - 20px);
        font-size: 12px;
        padding: 0 5px;
        margin: 0 2.5px;
        &:hover {
          z-index: 10;
          span {
            color: #838383;
          }
        }
        &:first-child {
          top: 0;
        }
        span {
          margin-left: 0px;
          transform: scale(0.7);
        }
      }
    }
  }
  .carrier-information p {
    font-size: 12px;
  }
}

.form-item {
  width: 100%;
  margin-bottom: 20px;
  .spinner {
    &:before {
      content: '';
      box-sizing: border-box;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border: 2px solid transparent;
      border-top-color: rgb(255, 255, 255);
      border-bottom-color: rgb(255, 255, 255);
      animation: spinner 0.8s ease infinite;
      margin-left: 10px;
    }
  }
  label {
    font-size: 14px;
    width: 100%;
    float: left;
    margin-bottom: 5px;
  }
  input {
    width: 96%;
    padding: 2%;
    color: #333;
    background: #f0f0f0;
    border: none;
    border-radius: 15px;
    &[type='file'] {
      background: #f0f0f0;
    }
  }
  button {
    border: #0b9e3c;
    background: #0b9e3c;
    color: #fff;
    padding: 8px 15px;
    cursor: pointer;
    border-radius: 15px;
    display: flex;
    &:hover {
      background: #fff;
      color: #0d5826;
      & .spinner {
        &::before {
          border-top-color: #333 !important;
          border-bottom-color: #333 !important;
        }
      }
    }
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.carrier-information {
  p {
    word-break: break-word;
  }
}
