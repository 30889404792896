.cursor-circle {
  width: 10px;
  height: 10px;
  background: #ef0d33;
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 9999;
}

* {
  &:hover .cursor-circle {
    width: 60px;
    height: 60px;
    transition: all, 0.4s;
  }
}
