.rewards-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -75px;
  margin-left: -75px;
  width: 100%;
  .reward-item {
    padding: 42px 75px;
    .info {
      &-head {
        padding: 15px 0;
        .title {
          display: block;
          font-size: 25px;
          font-weight: bold;
          letter-spacing: 0.88px;
          color: #e4e4e4;
        }
        .date {
          color: #838383;
        }
      }

      .desc {
        line-height: 24px;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .rewards-list {
    margin-right: -40px;
    margin-left: -40px;
    .reward-item {
      padding: 25px 40px;
    }
  }
}

@media #{$media-sm} {
  .rewards-list {
    margin-right: -38px;
    margin-left: -38px;
    .reward-item {
      padding: 22.5px 38px;
    }
  }
}
